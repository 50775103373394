import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';

const VideoStyles = styled.video`
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: -1;
`;

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>Rocky Brands Inc.</title>
      </Helmet>
      <VideoStyles autoPlay muted loop>
        <source
          src="https://cdn2.hubspot.net/hubfs/4392020/Georgia/Website/rocky_web_8_1.mp4"
          type="video/mp4"
        />
      </VideoStyles>
      <Layout>
        <div className="d-flex flex-column">
          <h1 className="text-uppercase text-center text-white align-self-center">We Are Rocky Brands</h1>
        </div>
      </Layout>
    </>
  );
}
